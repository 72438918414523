import React from 'react';
import { useIntl } from 'react-intl';
import tw from 'twin.macro';
import { GrantArticleHeading, SectionWrapper } from './style';

interface GrantArticleEligibilityProps {
  list: string[];
  heading?: string;
}

const ComponentWrapper = tw(SectionWrapper)`
    grid grid-cols-1 lg:grid-cols-[1fr_3fr] gap-4 lg:gap-20
`;
const ListWrapper = tw.ul`
    list-disc flex flex-col gap-2 lg:gap-8 mb-0
`;
const ListItem = tw.li`
    text-[14px] lg:text-[20px] text-base_text list-inside	
`;

const GrantArticleEligibility = ({
  list,
  heading,
}: GrantArticleEligibilityProps) => {
  const { formatMessage } = useIntl();

  return (
    <ComponentWrapper>
      <GrantArticleHeading>
        {heading ?? formatMessage({ id: 'grantArticle.eligibility.heading' })}
      </GrantArticleHeading>

      <ListWrapper>
        {list.map((text, index) => (
          <ListItem key={index}>{text}</ListItem>
        ))}
      </ListWrapper>
    </ComponentWrapper>
  );
};

export default GrantArticleEligibility;
