import React, { useState } from 'react';
import tw from 'twin.macro';
import { BenefitItem } from '../type';
import GrantArticleBenefitTabContent from './GrantArticleBenefitTabContent';

interface GrantArticleBenefitMobileCardProps extends BenefitItem {
  initialOpen: boolean;
}

const ComponentWrapper = tw.div`
    border-b border-b-[#D8DADE] py-3
`;
const LabelWrapper = tw.button`
    p-1 text-[#2D68C4] flex items-center gap-3
`;
const LabelText = tw.span`
    text-[16px] font-[500]
`;

const GrantArticleBenefitMobileCard = ({
  label,
  initialOpen,
  ...content
}: GrantArticleBenefitMobileCardProps) => {
  const [isOpenContent, setIsOpenContent] = useState(initialOpen);
  return (
    <ComponentWrapper>
      <LabelWrapper
        onClick={() => {
          setIsOpenContent(!isOpenContent);
        }}
      >
        <LabelText>{label}</LabelText>
        {isOpenContent ? (
          <span className="fa-regular fa-chevron-up" />
        ) : (
          <span className="fa-regular fa-chevron-down" />
        )}
      </LabelWrapper>
      {isOpenContent && <GrantArticleBenefitTabContent {...content} />}
    </ComponentWrapper>
  );
};

export default GrantArticleBenefitMobileCard;
