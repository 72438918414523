import Link from 'components/Link';
import React from 'react';
import { useIntl } from 'react-intl';
import tw from 'twin.macro';

interface GrantArticleHeroProps {
  title: string;
  subHeading: string;
}
const BackButtonLabel = tw.span`
  text-[16px] font-[500] group-hover:underline
`;
const SubHeading = tw.p`
  lg:font-[500] mb-0 text-white
`;
const Heading = tw.h1`
  text-[36px] md:text-[48px] !font-[600] mb-2 text-white
`;
const ComponentWrapper = tw.div`
  px-[30px] lg:px-0 py-[60px] lg:pt-[120px] lg:pb-[158px] text-base_text flex flex-col justify-center items-center text-center
`;

const GrantArticleHero = ({ title, subHeading }: GrantArticleHeroProps) => {
  const { formatMessage } = useIntl();
  return (
    <ComponentWrapper>
      <Link
        to="/grants"
        className="text-[#F5CD00] group hover:text-[#F5CD00] flex gap-3 items-center mb-[14px]"
      >
        <span className="fa-light fa-arrow-left" />
        <BackButtonLabel>
          {formatMessage({ id: 'grantArticle.hero.button.backToGrants' })}
        </BackButtonLabel>
      </Link>

      <SubHeading className="b5 ">{subHeading}</SubHeading>
      <Heading>{title}</Heading>

      <Link
        to="/contact-us"
        className="text-hubble_blue bg-white hover:text-white hover:bg-hubble_blue py-2 px-3"
      >
        {formatMessage({ id: 'grantArticle.hero.button.contactUs' })}
      </Link>
    </ComponentWrapper>
  );
};

export default GrantArticleHero;
