import React from 'react';
import { BenefitItem } from '../type';
import GrantArticleBenefitMobileCard from './GrantArticleBenefitMobileCard';

interface GrantArticleMobileBenefitListProps {
  list: BenefitItem[];
}

const GrantArticleMobileBenefitList = ({
  list,
}: GrantArticleMobileBenefitListProps) => {
  return (
    <>
      {list.map((item, index) => (
        <GrantArticleBenefitMobileCard
          key={item.id}
          {...item}
          initialOpen={index === 0 ? true : false}
        />
      ))}
    </>
  );
};

export default GrantArticleMobileBenefitList;
