import React from 'react';
import { Tabs } from 'antd';
import GrantArticleBenefitTabContent from './GrantArticleBenefitTabContent';
import { BenefitItem } from '../type';

interface GrantArticleWebBenefitListProps {
  list: BenefitItem[];
}

const GrantArticleWebBenefitList = ({
  list,
}: GrantArticleWebBenefitListProps) => {
  const BenefitTabList = list.map(({ id, label, ...tabContent }) => ({
    key: id,
    label,
    children: <GrantArticleBenefitTabContent {...tabContent} />,
  }));
  return (
    <div id="grant-web-benefit">
      <Tabs items={BenefitTabList} />
    </div>
  );
};

export default GrantArticleWebBenefitList;
