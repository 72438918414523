import Link from 'components/Link';
import React from 'react';
import { RequestBtnType } from '../type';

interface OutlinedBtnProps extends RequestBtnType {}

const OutlinedBtn = ({ label, to }: OutlinedBtnProps) => {
  return (
    <Link
      to={to}
      className="text-accent_blue_2 border border-accent_blue_2 text-[16px] font-[500] hover:text-accent_blue_2 hover:bg-accent_grey_2 py-2 px-3 w-fit"
    >
      {label}
    </Link>
  );
};

export default OutlinedBtn;
