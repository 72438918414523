import Link from 'components/Link';
import React from 'react';
import tw, { styled } from 'twin.macro';
import { BenefitItem } from '../type';

interface GrantArticleBenefitTabContentProps
  extends Omit<BenefitItem, 'id' | 'label'> {}
const ComponentWrapper = tw.div`
  grid grid-cols-1 lg:grid-cols-[1fr_2fr] gap-2 lg:gap-[32px] mt-5 lg:mt-10
`;
const ContentWrapper = tw.div`
flex flex-col gap-4 lg:justify-between
`;
const StyledLink = tw(Link)`
w-fit text-white font-[500] text-[14px] lg:text-[16px] leading-[21px] lg:leading-[24px] bg-accent_blue_2 px-3 py-2 hover:opacity-70 hover:text-white
`;
const Title = tw.h3`
  text-[20px] lg:text-[24px] lg:font-[500] mb-2
`;
const Description = styled.div`
  ol,
  ul {
    list-style-position: inside;
    list-style-type: disc;
  }
  ul {
    li {
      margin-left: 1rem;
    }
  }
`;

const GrantArticleBenefitTabContent = ({
  title,
  description,
  imgSrc,
  buttonLabel,
  buttonLink,
  imgAlt,
}: GrantArticleBenefitTabContentProps) => {
  return (
    <ComponentWrapper>
      <img
        src={imgSrc}
        alt={imgAlt}
        className="max-h-[370px] w-full object-cover"
      />
      <ContentWrapper>
        <Title>{title}</Title>
        <Description
          className="b6 mb-2"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <StyledLink to={buttonLink}>{buttonLabel}</StyledLink>
      </ContentWrapper>
    </ComponentWrapper>
  );
};

export default GrantArticleBenefitTabContent;
