import React from 'react';
import { useIntl } from 'react-intl';
import tw from 'twin.macro';
import { GrantArticleHeading } from '../style';
import { BenefitsContentType } from '../type';
import GrantArticleMobileBenefitList from './GrantArticleMobileBenefitList';
import GrantArticleWebBenefitList from './GrantArticleWebBenefitList';

interface GrantArticleBenefitsProps extends BenefitsContentType {}

const ComponentWrapper = tw.div`
  px-[30px] lg:px-0 py-[40px] lg:py-[60px]
`;
const SubText = tw.p`
  text-[14px] lg:text-[20px] mb-6 text-base_text mb-9 lg:mb-8
`;
const MobileContainer = tw.div`
  block lg:hidden
`;
const WebContainer = tw.div`
  hidden lg:block
`;

const GrantArticleBenefits = ({
  subText,
  list,
  heading,
}: GrantArticleBenefitsProps) => {
  const { formatMessage } = useIntl();
  return (
    <ComponentWrapper>
      <GrantArticleHeading>
        {heading ?? formatMessage({ id: 'grantArticle.benefits.heading' })}
      </GrantArticleHeading>
      <SubText>{subText}</SubText>

      <WebContainer>
        <GrantArticleWebBenefitList list={list} />
      </WebContainer>

      <MobileContainer>
        <GrantArticleMobileBenefitList list={list} />
      </MobileContainer>
    </ComponentWrapper>
  );
};

export default GrantArticleBenefits;
