import React, { ReactNode, useEffect, useState } from 'react';
import { GrantArticleHeading, SectionWrapper } from './style';
import { useIntl } from 'react-intl';
import { ApplicationStepType } from './type';
import tw from 'twin.macro';

interface GrantArticleApplicationProps {
  listOfStep: ApplicationStepType[];
  extraComponent?: ReactNode;
  heading?: string;
}

interface StepCardProps extends ApplicationStepType {
  index: number;
}

const CardWrapper = tw.div`
  bg-accent_grey_1 p-6 lg:p-8
`;
const StepTitle = tw.h3`
  text-[20px] lg:text-[24px] lg:font-[500] mb-2 text-base_text
`;
const StepDescription = tw.div`
  text-[14px] lg:text-[16px] mb-0 text-subtext
`;

const StepCard = ({ title, description, index }: StepCardProps) => (
  <CardWrapper>
    <StepTitle>{`${index}. ${` `} ${title}`}</StepTitle>
    <StepDescription
      dangerouslySetInnerHTML={{ __html: description.data.description }}
    />
  </CardWrapper>
);

const GrantArticleApplication = ({
  listOfStep,
  heading,
  extraComponent,
}: GrantArticleApplicationProps) => {
  const { formatMessage } = useIntl();
  const [listContainerCss, setListContainerCss] = useState('');
  useEffect(() => {
    const css = listOfStep?.length < 3 ? 'lg:grid-cols-2' : 'lg:grid-cols-3';
    setListContainerCss(css);
  }, [listOfStep]);
  return (
    <SectionWrapper className="space-y-4">
      <GrantArticleHeading>
        {heading ?? formatMessage({ id: 'grantArticle.application.heading' })}
      </GrantArticleHeading>
      {extraComponent}
      <div
        data-testid="grant-article-application-list-container"
        className={`mt-4 lg:mt-10 grid grid-cols-1 gap-x-6 gap-y-2 lg:gap-y-4 ${listContainerCss}`}
      >
        {listOfStep.map((step, index) => (
          <StepCard key={index} {...step} index={index + 1} />
        ))}
      </div>
    </SectionWrapper>
  );
};

export default GrantArticleApplication;
