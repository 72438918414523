import Link from 'components/Link';
import OutlinedBtn from 'components/requestForDemo/components/OutlinedBtn';
import React, { ReactNode } from 'react';

import tw from 'twin.macro';
import { GrantArticleRequestType } from './type';

interface GrantArticleRequestForDemoProps extends GrantArticleRequestType {}

interface GrantArticleRequestForDemoCardContainerProps {
  children: ReactNode;
}

const ComponentWrapper = tw.div`
  bg-accent_grey_1
`;
const ContentWrapper = tw.div`
  flex flex-col gap-6 mb-[20px]
`;
const Title = tw.h2`
  text-hubble_blue text-[24px] lg:text-[36px] !font-[500] m-0
`;
const SubTitle = tw.p`
  text-base_text text-[14px] lg:text-hubble_blue lg:text-[16px] font-[500] mb-1 lg:mb-0
`;
const Description = tw.p`
  text-[14px] lg:text-[16px] text-dark_black
`;

const GrantArticleRequestForDemoCardContainer = ({
  children,
}: GrantArticleRequestForDemoCardContainerProps) => {
  return (
    <div className="flex flex-col lg:flex-row justify-between items-center gap-[60px] px-[30px] xl:px-0 pt-[50px] lg:pt-[60px] ">
      {children}
    </div>
  );
};

const GrantArticleRequestForDemo = ({
  subHeader,
  header,
  strongDescription,
  description,
  btnLabel,
  btnLink,
  imgSrc,
  imgAlt,
}: GrantArticleRequestForDemoProps) => {
  return (
    <ComponentWrapper>
      <GrantArticleRequestForDemoCardContainer>
        <ContentWrapper>
          <div>
            <SubTitle>{subHeader}</SubTitle>
            <Title>{header}</Title>
          </div>
          <div className="request-demo-accent">
            <Description className="mb-1 font-[500]">
              {strongDescription}
            </Description>
            <Description className="m-0">{description}</Description>
          </div>

          <OutlinedBtn label={btnLabel} to={btnLink} />
        </ContentWrapper>

        <img
          src={imgSrc}
          alt={imgAlt}
          className="max-h-[420px] lg:self-end object-contain"
        />
      </GrantArticleRequestForDemoCardContainer>
    </ComponentWrapper>
  );
};

export default GrantArticleRequestForDemo;
